import { useCallback, useEffect, useState } from "react";

import { BasicBoardState, ILeaderBoard } from "../../../helpers/interface";
import {
  LOADING_DEFAULT_BOARD_STATE,
  NON_LOADING_DEFAULT_BOARD_STATE,
} from "../../../helpers/constants";
import { getLeaderBoard } from "../../../helpers/ServerApi";

const useGetLeaderBoard = () => {
  const [state, setState] = useState<BasicBoardState>(
    LOADING_DEFAULT_BOARD_STATE,
  );

  const fetchValue = useCallback(async () => {
    await getLeaderBoard().then((res: ILeaderBoard[]) => {
      if (!res) {
        setState(NON_LOADING_DEFAULT_BOARD_STATE);
        return;
      }
      setState({ isLoading: false, value: res });
    });
  }, []);

  useEffect(() => {
    fetchValue().catch((err) => {
      setState(NON_LOADING_DEFAULT_BOARD_STATE);
      console.error(`Failed to load all order the data: ${err.stack}`);
    });
  }, [fetchValue]);

  return state;
};

export default useGetLeaderBoard;
