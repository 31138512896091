import { useEffect, useMemo, useState } from "react";
import { useTokenBalance } from "../../redux/token/hooks";
import usePlaceBetsPemitted from "../../hooks/games/callbacks/usePlaceBetsPemitted";
import { escapeRegExp } from "../../helpers/functions";
import {
  useGetAssetApiData,
  useGetUserInput,
  useUpdateUserInputs,
} from "../../redux/application/hooks";
import TransferTypeModal from "./components/TransferTypeModal";
import PlayButton from "./components/PlayButton";
import IconLoader from "../../components/IconLoader";
import TokenSelectorModal from "../../components/TokenSelectorModal";
import { core } from "../../config";

// @ts-ignore
import diceSound from "../../static/sound/dice-sound.mp3";
import {ICollateralType} from "../../config/games";

const PlayingArea = () => {
  const userInput = useGetUserInput();

  const [input, setInput] = useState<string>(userInput.input);
  const [guess, setGuess] = useState<number>(userInput.guess);
  const [tokenSelectorModal, setTokenSelectorModal] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<ICollateralType>(userInput.token);
  const [depositModal, setDepositModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const apiAssetData = useGetAssetApiData();
  const updateUserInput = useUpdateUserInputs();
  const ethUsdValue = apiAssetData[selectedToken] || undefined;
  const betAmountInUsd = ethUsdValue
    ? Number(ethUsdValue) * Number(input)
    : undefined;

  const tokenBalance = useTokenBalance(selectedToken);

  const winChance = useMemo(() => 100 - guess, [guess]);

  const multiplier = useMemo(() => 100 / (100 - Number(guess)), [guess]);
  const payout = useMemo(() => Number(input) * multiplier, [input, multiplier]);
  const payoutInUsd = ethUsdValue
    ? Number(ethUsdValue) * Number(payout)
    : undefined;

  const playWithPermit = usePlaceBetsPemitted(
    Number(input),
    guess,
    selectedToken,
  );

  const enforcer = (nextUserInput: string) => {
    const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      setInput(nextUserInput);
      // setBetAmount(Math.max(Number(nextUserInput), 0.001).toString());
    }
  };

  const onEasybuttonClick = (perc: number) => {
    setInput((Number(input) * (perc / 100)).toString());
  };

  const handlePlay = () => {
    playSound(diceSound);
    playWithPermit(
      () => setLoading(true),
      () => setLoading(false),
      () => setLoading(false),
    );
  };

  useEffect(() => {
    core.queue.initHash();
  }, []);

  useEffect(() => {
    updateUserInput(guess, input, selectedToken);
  }, [input, guess, updateUserInput, selectedToken]);

  useEffect(() => {
    if (userInput.token !== selectedToken) setSelectedToken(userInput.token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput.token]);

  const playSound = (url: string) => {
    const audio = new Audio(url);
    return audio.play();
  };

  return (
    <div id={"playing-area"} className={"box-wrapper"}>
      {depositModal && (
        <TransferTypeModal
          open={depositModal}
          handleClose={() => setDepositModal(false)}
        />
      )}
      {tokenSelectorModal && (
        <TokenSelectorModal
          open={tokenSelectorModal}
          handleClose={() => setTokenSelectorModal(!tokenSelectorModal)}
          selectedToken={selectedToken}
          setSelectedToken={(token) => {
            setTokenSelectorModal(false);
            setSelectedToken(token);
          }}
        />
      )}
      <div className={"playing-area-row"}>
        <div className={"bet-amount-area"}>
          <div className={"label-field"}>
            <p className={"label"}>BET AMOUNT</p>
            <p className={"data"}>
              Balance: {tokenBalance.toFixed(5)} {selectedToken}
            </p>
          </div>

          <div className={"input-area"}>
            <div className="sub-input-area">
              <input
                value={input}
                onChange={(event) => enforcer(event.target.value)}
                className={"input-field"}
              />
              {/*<div className={"token-view"}>ETH</div>*/}
              <div
                className={"token-view-with-dropdown"}
                onClick={() => setTokenSelectorModal(true)}
              >
                <span>{selectedToken}</span>
                <IconLoader
                  iconName={"ArrowDown"}
                  iconType={"arrow"}
                  width={14}
                />
              </div>
            </div>
            <div className="mo-button-row">
              <div
                className={"secondary-btn"}
                onClick={() => onEasybuttonClick(50)}
              >
                1/2x
              </div>
              <div
                className={"secondary-btn"}
                onClick={() => onEasybuttonClick(200)}
              >
                2x
              </div>
            </div>
          </div>
          <div className={"fiat-value"}>
            ${betAmountInUsd !== undefined ? betAmountInUsd.toFixed(2) : "-"}
          </div>
        </div>
        <div className={"payout-area"}>
          <p className={"label"}>PAYOUT ON WIN</p>
          <div className={"payout-value input-field"}>{payout.toFixed(6)}</div>
          <div className={"fiat-value"}>
            ${payoutInUsd !== undefined ? payoutInUsd.toFixed(2) : "-"}
          </div>
        </div>
      </div>
      <div className={"slider"}>
        <input
          className="input-slider"
          type="range"
          step="1"
          value={guess}
          min="1"
          max="100"
          onChange={(event) =>
            setGuess(Math.max(5, Math.min(Number(event.target.value), 99)))
          }
        />
      </div>
      <div className={"info-row"}>
        <div className={"info-col"}>
          <p className={"info-label label"}>ROLL ABOVE</p>
          <p className={"info-value"}>{guess}</p>
        </div>
        <div className={"info-col"}>
          <p className={"info-label label"}>PAYOUT</p>
          <p className={"info-value"}>{multiplier.toFixed(2)}X</p>
        </div>
        <div className={"info-col"}>
          <p className={"info-label label"}>WIN CHANCE</p>
          <p className={"info-value"}>{Number(winChance).toFixed(0)}%</p>
        </div>
      </div>

      <PlayButton
        tokenBalance={tokenBalance}
        diceValue={guess}
        betAmount={input}
        handlePlay={handlePlay}
        loading={loading}
      />
    </div>
  );
};

export default PlayingArea;
