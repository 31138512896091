import { BigNumber } from "ethers";
import {
  ApplicationState,
  BasicBoardState,
  BasicBooleanState,
  BasicDepositTxnState,
  BasicNumberState,
  BasicReferralState,
  BasicState,
  BasicStringState,
  BasicWithdrawTxnState,
} from "./interface";
export const DECIMALS_18 = BigNumber.from(10).pow(18);
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_UINT_256 =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const INITIAL_APP_STATE: ApplicationState = {
  blockNumber: {},
  popupList: [],
  assetPrices: {},
  walletModalOpen: false,
  seedHash: "",
  settingsMenuOpen: false,
  confettiOpen: false,
  referredBy: "",
  userInput: {
    guess: 50,
    input: "10",
    token: "FUN",
  },
  gameIDs: [],
  depositAddress: "",
};

export const LOADING_DEFAULT_BASIC_STATE: BasicState = {
  isLoading: true,
  value: BigNumber.from(0),
};

export const LOADING_DEFAULT_BOOLEAN_STATE: BasicBooleanState = {
  isLoading: true,
  value: true,
};

export const NON_LOADING_DEFAULT_BOOLEAN_STATE: BasicBooleanState = {
  isLoading: false,
  value: false,
};

export const NON_LOADING_DEFAULT_BASIC_STATE: BasicState = {
  isLoading: false,
  value: BigNumber.from(0),
};

export const LOADING_DEFAULT_NUMBER_BASIC_STATE: BasicNumberState = {
  isLoading: true,
  value: 0,
};

export const NON_LOADING_DEFAULT_NUMBER_BASIC_STATE: BasicNumberState = {
  isLoading: false,
  value: 0,
};

export const LOADING_DEFAULT_STRING_BASIC_STATE: BasicStringState = {
  isLoading: true,
  value: "",
};

export const NON_LOADING_DEFAULT_STRING_BASIC_STATE: BasicStringState = {
  isLoading: false,
  value: "",
};

export const LOADING_DEFAULT_BOARD_STATE: BasicBoardState = {
  isLoading: true,
  value: [],
};

export const NON_LOADING_DEFAULT_BOARD_STATE: BasicBoardState = {
  isLoading: false,
  value: [],
};

export const LOADING_DEFAULT_REFERRAL_STATE: BasicReferralState = {
  isLoading: true,
  value: [],
};

export const NON_LOADING_DEFAULT_REFERRAL_STATE: BasicReferralState = {
  isLoading: false,
  value: [],
};

export const LOADING_DEFAULT_DepositTxn_STATE: BasicDepositTxnState = {
  isLoading: true,
  value: [],
};

export const NON_LOADING_DEFAULT_DepositTxn_STATE: BasicDepositTxnState = {
  isLoading: false,
  value: [],
};

export const LOADING_DEFAULT_WithdrawTxn_STATE: BasicWithdrawTxnState = {
  isLoading: true,
  value: [],
};

export const NON_LOADING_DEFAULT_WithdrawTxn_STATE: BasicWithdrawTxnState = {
  isLoading: false,
  value: [],
};
