import "./style.sass";
import { useState } from "react";
import AccountHeader from "../../AccountHeader";
import ReferralRanking from "./components/ReferralsRanking";
import MyReferral from "./components/MyReferral";
import ReferralCodeList from "./components/ReferralCodeList";

type IReferralSection = "top-referral" | "my-referral" | "referral-codes";

const Referral = () => {
  const [referralSection, setReferralSection] =
    useState<IReferralSection>("referral-codes");

  return (
    <div id="referral-main">
      <AccountHeader title={"REFER & EARN"} />

      <div className="referral-section">
        <div
          className={`referral-section-selectors ${referralSection === "referral-codes"? "selected": ""}`}
          onClick={() => setReferralSection("referral-codes")}
        >
          My Referral Codes
        </div>
        <div
          className={`referral-section-selectors ${referralSection === "top-referral"? "selected": ""}`}
          onClick={() => setReferralSection("top-referral")}
        >
          Top Referrals
        </div>
        <div
          className={`referral-section-selectors ${referralSection === "my-referral"? "selected": ""}`}
          onClick={() => setReferralSection("my-referral")}
        >
          My Referrals
        </div>
      </div>
      {referralSection === "top-referral" && <ReferralRanking />}
      {referralSection === "my-referral" && <MyReferral />}
      {referralSection === "referral-codes" && <ReferralCodeList />}
    </div>
  );
};

export default Referral;
