import Intro from "./Intro";
import Prizes from "./Prizes";
import "./styles.sass";
import Banners from "./Banners";
import GameCards from "./components/GameCards";

const Home = () => {
  return (
    <div id={"home"}>
      <Banners />
      <Intro />
      <Prizes />

      <div className={"games-section"}>
        <p className={"title"}>Games</p>
        <div className={"games-row"}>
          <GameCards />
          {/* <GameCards />
          <GameCards />
          <GameCards />
          <GameCards />
          <GameCards />
          <GameCards /> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
