import useGetReferralBoard from "../../../../../hooks/games/state/useGetReferralBoard";
import RewardsRow from "./RewardsRow";

const ReferralsRanking = () => {
  const referralBoard = useGetReferralBoard();
  const headers = [
    {
      key: "betId",
      flex: 0.4,
      name: "Ranking",
    },
    {
      key: "playerName",
      flex: 0.4,
      name: "Referral Code",
    },
    {
      key: "payout",
      flex: 0.4,
      name: "USD Earned",
    },
    {
      key: "profit",
      flex: 0.4,
      name: "Referred Players",
    },
  ];

  return (
    <div className={"top-referrals-data"}>
      <div className="box-wrapper">
        <div className="title">Top Referrals</div>
        <div className="explain">
          Make referrals and get to the top of the leaderboard to earn rewards
          and prizes.
        </div>
      </div>
      <div id={"referrals-table"}>
        <div className={"table-main"}>
          <div className={"table-head"}>
            {headers.map((data) => {
              return (
                <div
                  className={"table-head-li"}
                  key={data.key}
                  style={{ flex: data.flex }}
                >
                  {data.name}
                </div>
              );
            })}
          </div>
          <div className={"table-body"}>
            {referralBoard.isLoading && <p className={"info"}>Loading Referral Rankings ....</p>}
            {referralBoard.value.length === 0 && !referralBoard.isLoading
              ? <p className={"info"}>No referrals found</p>
              : referralBoard.value.map((data, index) => {
                return <RewardsRow key={index} data={data} rank={index} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralsRanking;
