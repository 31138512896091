import { IChat } from "../modules/chat";
import { ISendWithPermitParams as IPlayParams } from "../protocol/BetsQueue";
import api from "./apiCaller";
import {
  ILeaderBoard,
  IReferralBoard,
  IRelayResponseData,
  IUserAssetBalance,
  IAssetPrices,
  IUser,
  IBetResult,
  IReferralCodes,
  IMyReferral, IBalance,
} from "./interface";

//Ranking Board APIs
export const getLeaderBoard = async () => {
  return await api<ILeaderBoard[]>("leaderBoard/bets");
};

export const getReferralLeaderBoard = async () =>
  await api<IReferralBoard[]>("leaderBoard/referral");

//Login APIs

export const GoogleLogin = async () => await api("google/login");

//User Data APIs

export const userUpdate = async (body: any) =>
  await api("users/update", "POST", body);

export const getUserDetails = async () => await api("users/me");

export const getUserBalance = async () =>
  await api<IBalance[]>("users/balances");

export const depositTxns = async () => await api("users/deposit/transactions");

export const withdrawTxns = async () =>
  await api("users/withdraw/transactions");

//Chat APIs

export const sendMessage = async (message: string) =>
  await api("chats", "POST", { message });

export const getAllChat = async () => await api<IChat[]>("chats");

// Address APIs

export const getDepositAddress = async () =>
  await api<{ depositAddress: string }>("users/address");

export const withdrawal = async (body: any) =>
  await api("users/withdrawal", "POST", body);

// Game APIs

export const play = async (game: string, params: IPlayParams) =>
  await api<IBetResult>(`plays/${game}`, "POST", params);

export const getUserBets = async () => await api<IBetResult[]>("bets/me");

export const relayPlayWithPermit = async (data: IPlayParams) =>
  await api<IRelayResponseData>(`relay/playWithPermit`, "post", data);

export const generateSha256Hash = async () =>
  await api<{ shaHash: string }>(`seeds`, "post");

export const getDollarValues = async () =>
  await api<IAssetPrices>(`prices/assets`, "get");

export const getCurrentUser = async () =>
  await api<{ user: IUser }>(`users/me`, "get");

export const addReferralCode = async (body: any) =>
  await api("referrals/add", "POST", body);

export const getUserReferralCodes = async () =>
  await api<{ codes: IReferralCodes[] }>("referrals/codes");

export const getMyReferrals = async () =>
  await api<{ referrals: IMyReferral[] }>("referrals/me");
