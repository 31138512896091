import "./style.sass";
import { useState } from "react";
import { useGetReferredBy } from "../../../redux/application/hooks";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../../helpers/ServerApi";
import Button from "../../../components/Button";
import useUpdateUser from "../../../hooks/update/useUpdateUser";
import useUpdateBalances from "../../../hooks/update/useUpdateBalances";
import WelcomeModal from "../../../components/WelcomeModal";

const UsernameSetup = () => {
  const navigate = useNavigate();
  const [username, _setUsername] = useState("");
  const referralCode = useGetReferredBy();
  const [nameTaken, setNameTaken] = useState<boolean>(false);
  const [welcomeModal, setWelcomeModal] = useState<boolean>(false);

  const updateBalances = useUpdateBalances();
  const updateUser = useUpdateUser();

  const setUsername = (str: string) => {
    const usernameRegex = /^([a-zA-Z0-9_]){0,20}$/;
    const validUsername = str.match(usernameRegex);
    if (!str) _setUsername("");
    if (validUsername) _setUsername(str);
  };

  return (
    <div id="username-page">
      <WelcomeModal open={welcomeModal} handleClose={() => navigate('/')}/>
      <div className={"username-layout box-wrapper"}>
        <div className="text-center">
          Welcome to Mooncake Bets! To complete your registration, please choose
          a uesrname
        </div>
        <br />
        <div className={"input-area"}>
          <input
            value={username}
            defaultValue={username}
            onChange={(event) => setUsername(event.target.value)}
            className={"input-field"}
            disabled={false}
          />
        </div>
        {nameTaken && (
          <span className="error-msg">Username already taken!!</span>
        )}
        <div className={"cta-btn"}>
          <Button
            disabled={username.length <= 3}
            className={"primary-btn"}
            onClick={async () => {
              await userUpdate({ username: username, referralCode })
                .then(() => {
                  updateUser();
                  updateBalances();
                  setWelcomeModal(true)
                  // navigate("/");
                })
                .catch((e) => {
                  setNameTaken(true);
                });
            }}
          >
            Update Username
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UsernameSetup;
