import { BigNumber, BigNumberish } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";

export function getBalance(balance: BigNumber, decimals = 18): string {
  try {
    return formatUnits(balance, decimals);
  } catch (err) {
    return "0";
  }
}

export const getDisplayBalance = (
  balance: BigNumberish,
  decimals = 18,
  fractionDigits = 3,
): string => {
  try {
    const formattedBalance: string = getBalance(
      BigNumber.from(balance),
      decimals,
    );
    const decimalsPointIndex = formattedBalance.indexOf(".");
    if (decimalsPointIndex === -1) return formattedBalance;
    return (
      formattedBalance.slice(0, decimalsPointIndex) +
      "." +
      formattedBalance.slice(
        decimalsPointIndex + 1,
        decimalsPointIndex + 1 + fractionDigits,
      )
    );
  } catch (error) {
    return "0";
  }
};

export const formatToBN = (
  value: number | string,
  decimals: number = 18,
): BigNumber => {
  try {
    const [beforeDecimals, afterDecimal] = `${value}`.split(".");

    const beforeDecimalsPrecisionText = beforeDecimals?.slice(0, 18) || "0";
    const afterDecimalsPrecisionText = afterDecimal?.slice(0, decimals) || "0";
    const fixedPrecisionValue = `${beforeDecimalsPrecisionText}.${afterDecimalsPrecisionText}`;

    return BigNumber.from(parseUnits(`${fixedPrecisionValue}`, decimals));
  } catch (error) {
    return BigNumber.from("0");
  }
};

export const getTokenToUsd = (
  tokenVal: BigNumber,
  decimal: number,
  unitValUsd: number,
): number => {
  if (unitValUsd === undefined) return 0;
  const getVal = Number(getDisplayBalance(tokenVal, decimal, 10));
  return getVal * unitValUsd;
};
