import "./style.sass";
import { BigNumber } from "ethers";
import { getDisplayBalance } from "../../helpers/formatBalance";
import { useMemo } from "react";

const Referral = () => {
  const pointsBalance = 0; // useTokenBalance(chainId, core.getLoyaltyPointsToken(chainId)?.address)
  const totalSupply = 0; // useTokenTotalSupply(chainId, core.getLoyaltyPointsToken(chainId)?.address)

  const percentage = useMemo(() => {
    if (
      BigNumber.from(pointsBalance).isZero() ||
      BigNumber.from(totalSupply).isZero()
    )
      return 0;
    return (
      BigNumber.from(pointsBalance).mul(100000).div(totalSupply).toNumber() /
      1000
    );
  }, [pointsBalance, totalSupply]);

  return (
    <div id="rewards">
      <h2 className="referral-header">Rewards</h2>
      <span className="referral-sub-header">
        Place bets to earn loyalty points and earn your share of the upcoming
        MCAKE token launch. The bigger the bet, the more points you earn.
      </span>

      <span className="referral-sub-header">
        You have a total of{" "}
        <span>{getDisplayBalance(pointsBalance)} points</span>. You will earn
        for upto <span>{percentage}% of the MCAKE supply</span>
        {/*} and you currently have{" "}
        <span>rank 0</span>*/}
        . Continue earning points by placing bets onto the casino.
      </span>

      <div className="hr" />
      {/*
      <h3 className="referral-header">Top Loyalists</h3>
      <span className="referral-sub-header">
        The top wallets with ownership of the loyalty points are shown here. The
        more points you have, the
      </span>
      <LoyalistsRanking /> */}
    </div>
  );
};

export default Referral;
