import React from "react";
import gameIcon from "../../../static/images/gameIcon.png";
import { useNavigate } from "react-router-dom";

const GameCards = () => {
  const navigate = useNavigate();

  return (
    <div className={"games-col"}>
      <div
        className={"game-card"}
        style={{ backgroundImage: `url(${gameIcon})` }}
        onClick={() => {
          navigate("/games/dice");
        }}
      />
    </div>
  );
};

export default GameCards;
