import Modal from "../Modal";
import {IModalProp} from "../../helpers/interface";

import './style.sass';
import Button from "../Button";
import {useNavigate} from "react-router-dom";

const WelcomeModal = (props: IModalProp) => {
  const navigate = useNavigate();
  return (
    <Modal open={props.open} handleClose={props.handleClose} title={"Welcome To Mooncake Bets"}>
      <div className={"welcome-modal"}>
        <p>Welcome to mooncake bets Lorem Ipsum is simply dummy text of
          <br/>the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy
          text ever since the 1500s, when an unknown printer took a galley o
          f type and scrambled it to make a type specimen book</p>
        <p className={"highlight"}> You have got $10 as bonus to play </p>
        <div className={"cta"}>
          <Button tracking_id={"welcome_join_discord"} className={"primary-btn"} onClick={() => window.open("https://discord.com")}>
            Join Our Discord
          </Button>
          <Button tracking_id={"get_started"} className={"primary-btn"} onClick={() => navigate("/")}>Get Started</Button>
        </div>
      </div>
    </Modal>
  )
}

export default WelcomeModal;
