import { useEffect } from "react";
import useUpdateUserReferrals from "../../../../../hooks/update/useUpdateUserReferrals";
import { useGetUserReferrals } from "../../../../../redux/user/hooks";
import RewardsRow from "./RewardsRow";

const MyReferral = () => {
  const updateUserReferrals = useUpdateUserReferrals();
  const referrals = useGetUserReferrals();

  const headers = [
    {
      key: "playerName",
      flex: 0.3,
      name: "Username",
    },
    {
      key: "referralCode",
      flex: 0.3,
      name: "Code Used",
    },
    {
      key: "payout",
      flex: 0.3,
      name: "USD Wagered",
    },
  ];

  // download all the referrals
  useEffect(() => {
    updateUserReferrals();
  }, [updateUserReferrals]);

  return (
    <div className={"top-referrals-data"}>
      <div className="box-wrapper">
        <div className="title">Your Top Referrals</div>
        <div className="explain">
          Players that you have used your referral code will show up here.
        </div>
      </div>
      <div id={"referrals-table"}>
        <div className={"table-main"}>
          <div className={"table-head"}>
            {headers.map((data) => {
              return (
                <div
                  className={"table-head-li"}
                  key={data.key}
                  style={{ flex: data.flex }}
                >
                  {data.name}
                </div>
              );
            })}
          </div>
          <div className={"table-body"}>
            {referrals.length === 0
              ? <p className={"info"}>No referrals found</p>
              : referrals.map((data, index) => (
                <div key={index} className={"table-row"}>
                  <div className={"table-row-li"} style={{ flex: 0.3 }}>
                    {data.username}
                  </div>
                  <div className={"table-row-li"} style={{ flex: 0.3 }}>
                    {data.referredByCode.code}
                  </div>
                  <div className={"table-row-li"} style={{ flex: 0.3 }}>
                    ${(data.usdWagered || 0).toFixed(2)}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyReferral;
