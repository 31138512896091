import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home.page";
import LeaderboardPage from "./pages/leaderboard.page";
import RewardsPage from "./pages/rewards.page";
import DiceGame from "./pages/dice.game";
import SlotGame from "./pages/slot.page";
import ChatPage from "./pages/chat.page";
import RedirectPage from "./pages/redirect.page";
import AccountPage from "./pages/account.page";
import Page from "./layouts/Page";

export default function Navigation() {
  return (
    <Page>
      <Routes>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"/games/dice"} element={<DiceGame />} />
        <Route path={"/games/slot"} element={<SlotGame />} />
        <Route path={"/leaderboard"} element={<LeaderboardPage />} />
        <Route path={"/rewards"} element={<RewardsPage />} />
        <Route path={"/chat"} element={<ChatPage />} />
        <Route path="/account" element={<Navigate to="/account/profile" />} />
        <Route path={"/account/:page"} element={<AccountPage />} />
        <Route path={"/auth/redirect/callback"} element={<RedirectPage />} />
      </Routes>
    </Page>
  );
}
