import { getUserBalance } from "../../helpers/ServerApi";
import { HttpError } from "../../helpers/apiCaller";
import {logout, setBalances} from "../../redux/user/actions";
import { updateBalanceOf } from "../../redux/token/actions";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

const useUpdateBalances = () => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    await getUserBalance()
      .then((res) => {
        dispatch(
          setBalances({
            balances: res,
          })
        )
        res.forEach((bal) => {
          dispatch(
            updateBalanceOf({
              bal: bal.balance,
              token: bal.currency.symbol,
              who: bal.user,
            }),
          );
        });
      })

      .catch((err: HttpError) => {
        if (err.status === 401) dispatch(logout());
      });
  }, [dispatch]);
};

export default useUpdateBalances;
