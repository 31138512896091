import Modal from "../Modal";
import { IModalProp } from "../../helpers/interface";
import {diceGame, ICollateralType} from "../../config/games";

import "./style.sass";
import TokenRow from "./TokenRow";

interface IProps {
  selectedToken: ICollateralType;
  setSelectedToken: (data: ICollateralType) => void;
}

const TokenSelectorModal = (props: IModalProp & IProps) => {
  return (
    <Modal
      open={props.open}
      handleClose={props.handleClose}
      className={"token-selector-modal"}
      title={"Select Token"}
    >
      <div className={"option-items"}>
        {// @ts-ignore
          diceGame.collateral.map((data: ICollateralType, index) => {
          return (
            <TokenRow index={index} selectedToken={props.selectedToken} passingToken={data} setSelectedToken={props.setSelectedToken}/>
          );
        })}
      </div>
    </Modal>
  );
};

export default TokenSelectorModal;
