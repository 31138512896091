import "./app-header.sass";
import { IconName } from "@fortawesome/free-solid-svg-icons";
import { useGetLoggedIn } from "../../redux/user/hooks";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AccountButton from "../../components/AccountButton/AccountButton";
import BalanceButton from "../../components/BalanceButton";
import brandLogo from "../../static/images/brandLogo.png";
import TransferTypeModal from "../../modules/dice/components/TransferTypeModal";
import GlobalTokenSelector from "../../components/GlobalTokenSelector";

interface MenuItemProps {
  id: string;
  name: string;
  redirect: string;
  show: ("desktop" | "mobile")[];
  icon: IconName;
}

export const MenuItems: MenuItemProps[] = [
  {
    id: "home",
    name: "Home",
    redirect: "/",
    icon: "house",
    show: ["mobile", "desktop"],
  },
  {
    id: "dice",
    name: "Dice",
    redirect: "/games/dice",
    icon: "puzzle-piece",
    show: ["desktop", "mobile"],
  },
  // {
  //   id: "referral",
  //   name: "Referrals",
  //   redirect: "/account/referral",
  //   icon: "user-group",
  //   show: ['desktop']
  // },
  // {
  //   id: "referral",
  //   name: "Referrals",
  //   redirect: "/account/profile",
  //   icon: "user-group",
  //   show: ['mobile']
  // },
  {
    id: "leaderboard",
    name: "Leaderboard",
    redirect: "/leaderboard",
    icon: "chart-simple",
    show: ["desktop"],
  },
  {
    id: "rewards",
    name: "Rewards",
    redirect: "/rewards",
    icon: "hand-holding-dollar",
    show: ["desktop", "mobile"],
  },
  {
    id: "chat",
    name: "Chat",
    redirect: "/chat",
    icon: "comment",
    show: ["mobile"],
  },
];

const AppHeader = () => {
  const isMobile = useMediaQuery({ maxWidth: "1024px" });
  const navigate = useNavigate();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const isLoggedIn = useGetLoggedIn();

  return (
    <div id={"app-header"}>
      {openDeposit && (
        <TransferTypeModal
          open={openDeposit}
          handleClose={() => setOpenDeposit(false)}
        />
      )}
      <div className={"branding-w-logo cursor"} onClick={() => navigate("/")}>
        <img src={brandLogo} alt={"brand-logo"} className={"brand-logo"} />
        {!isMobile && (
          <div className={"branding"}>
            <p>Mooncake Bets</p>
            <div className="sub">A fast, fair, crypto casino</div>
          </div>
        )}
      </div>
      <div className={"right-cta"}>
        {isLoggedIn && <BalanceButton />}
        {isLoggedIn && <GlobalTokenSelector />}
        <AccountButton />
      </div>
    </div>
  );
};

export default AppHeader;
